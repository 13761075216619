import {
  federalSingleTaxBrackets2024,
  federalMFJTaxBrackets2024,
  federalMFSTaxBrackets2024,
  federalHOHTaxBrackets2024,
  newYorkStateSingleTaxBrackets2024,
  newYorkStateMFJTaxBrackets2024,
  newYorkStateMFSTaxBrackets2024,
  newYorkStateHOHTaxBrackets2024,
  newYorkCitySingleTaxBrackets2024,
  newYorkCityMFJTaxBrackets2024,
  newYorkCityMFSTaxBrackets2024,
  newYorkCityHOHTaxBrackets2024
}
  from '../constants/values2024';
import {
  federalSingleTaxBrackets2025,
  federalMFJTaxBrackets2025,
  federalMFSTaxBrackets2025,
  federalHOHTaxBrackets2025,
  newYorkStateSingleTaxBrackets2025,
  newYorkStateMFJTaxBrackets2025,
  newYorkStateMFSTaxBrackets2025,
  newYorkStateHOHTaxBrackets2025,
  newYorkCitySingleTaxBrackets2025,
  newYorkCityMFJTaxBrackets2025,
  newYorkCityMFSTaxBrackets2025,
  newYorkCityHOHTaxBrackets2025
}
  from '../constants/values2025';
import taxBracketTaxFunction from './taxBracketTaxFunction';
import schDTaxWorksheetLineThresholdCalculation from './schDTaxWorksheetLineThresholdCalculation';
import enterSmallerNumberFunction from './enterSmallerNumberFunction';
import { ITaxBracketModel } from '../types';

interface ITaxBrackets {
  [year: string]: {
    federalSingleTaxBrackets: ITaxBracketModel[];
    federalMFJTaxBrackets: ITaxBracketModel[];
    federalMFSTaxBrackets: ITaxBracketModel[];
    federalHOHTaxBrackets: ITaxBracketModel[];
    newYorkStateSingleTaxBrackets: ITaxBracketModel[];
    newYorkStateMFJTaxBrackets: ITaxBracketModel[];
    newYorkStateMFSTaxBrackets: ITaxBracketModel[];
    newYorkStateHOHTaxBrackets: ITaxBracketModel[];
    newYorkCitySingleTaxBrackets: ITaxBracketModel[];
    newYorkCityMFJTaxBrackets: ITaxBracketModel[];
    newYorkCityMFSTaxBrackets: ITaxBracketModel[];
    newYorkCityHOHTaxBrackets: ITaxBracketModel[];
  };
}

const taxBrackets: ITaxBrackets = {
  '2024': {
    'federalSingleTaxBrackets': federalSingleTaxBrackets2024,
    'federalMFJTaxBrackets': federalMFJTaxBrackets2024,
    'federalMFSTaxBrackets': federalMFSTaxBrackets2024,
    'federalHOHTaxBrackets': federalHOHTaxBrackets2024,
    'newYorkStateSingleTaxBrackets': newYorkStateSingleTaxBrackets2024,
    'newYorkStateMFJTaxBrackets': newYorkStateMFJTaxBrackets2024,
    'newYorkStateMFSTaxBrackets': newYorkStateMFSTaxBrackets2024,
    'newYorkStateHOHTaxBrackets': newYorkStateHOHTaxBrackets2024,
    'newYorkCitySingleTaxBrackets': newYorkCitySingleTaxBrackets2024,
    'newYorkCityMFJTaxBrackets': newYorkCityMFJTaxBrackets2024,
    'newYorkCityMFSTaxBrackets': newYorkCityMFSTaxBrackets2024,
    'newYorkCityHOHTaxBrackets': newYorkCityHOHTaxBrackets2024
  },
  '2025': {
    'federalSingleTaxBrackets': federalSingleTaxBrackets2025,
    'federalMFJTaxBrackets': federalMFJTaxBrackets2025,
    'federalMFSTaxBrackets': federalMFSTaxBrackets2025,
    'federalHOHTaxBrackets': federalHOHTaxBrackets2025,
    'newYorkStateSingleTaxBrackets': newYorkStateSingleTaxBrackets2025,
    'newYorkStateMFJTaxBrackets': newYorkStateMFJTaxBrackets2025,
    'newYorkStateMFSTaxBrackets': newYorkStateMFSTaxBrackets2025,
    'newYorkStateHOHTaxBrackets': newYorkStateHOHTaxBrackets2025,
    'newYorkCitySingleTaxBrackets': newYorkCitySingleTaxBrackets2025,
    'newYorkCityMFJTaxBrackets': newYorkCityMFJTaxBrackets2025,
    'newYorkCityMFSTaxBrackets': newYorkCityMFSTaxBrackets2025,
    'newYorkCityHOHTaxBrackets': newYorkCityHOHTaxBrackets2025
  }
}

const taxCalcFunction = (
  taxableIncome: number,
  filingStatus: string,
  residency: string,
  taxYear: string,
  shortTermCapital = 0,
  longTermCapital = 0,
  qualifiedDividends = 0
): number => {

  let taxBracket: ITaxBracketModel[] | undefined = undefined;
  let taxBracket2: ITaxBracketModel[] | undefined = undefined;
  const expr: string = filingStatus + ' & ' + residency;
  let calculatedTax = 0;

  // refactor capital gains into its own function?
  // Sch D Line 1 is taxableIncome
  // Sch D Line 2 is qualifiedDividends
  const totalCapitalGains: number = shortTermCapital + longTermCapital;
  let schDLineThree = 0;
  let capitalGainsPlusQualifiedDividends = 0; // Sch D Line 4
  let taxableIncomeLessQualDivAndCapGains = 0; // Sch D Line 5
  const schDLineSixThreshold: number = schDTaxWorksheetLineThresholdCalculation(filingStatus, 'SchDLineSix', taxYear);
  let schDLineSeven = 0;
  let schDLineEight = 0;
  let schDLineNine = 0;
  let schDLineTen = 0;
  const schDLineEleven: number = schDLineNine;
  let schDLineTwelve = 0;
  const schDLineThirteenThreshold: number = schDTaxWorksheetLineThresholdCalculation(filingStatus, 'SchDLineThirteen', taxYear);
  let schDLineFourteen = 0;
  let schDLineFifteen = 0;
  let schDLineSixteen = 0;
  let schDLineSeventeen = 0;
  let schDLineEighteen = 0;
  let schDLineNineteen = 0;
  let schDLineTwenty = 0;
  let schDLineTwentyOne = 0;
  let calculatedTaxOfTaxableIncomeLessQualDivAndCapGains = 0; // Schd D Line 22
  let totalCapitalGainsTax = 0; // Sch D Line 23 Part 1
  let totalCalculatedTaxBenefittedFromCapGainsRate = 0; // Sch D Line 23 Part 2 TOTAL

  if (taxableIncome < 1) {
    return calculatedTax;
  }
  else if (residency === 'None') {
    return calculatedTax;
  }
  else if (residency === 'Federal') {
    if (longTermCapital >= totalCapitalGains) {
      if (totalCapitalGains <= 0) {
        schDLineThree = 0;
      }
      else {
        schDLineThree = totalCapitalGains;
      }
    }
    else {
      if (longTermCapital <= 0) {
        schDLineThree = 0;
      }
      else {
        schDLineThree = longTermCapital;
      }
    }

    capitalGainsPlusQualifiedDividends += schDLineThree + qualifiedDividends;
    taxableIncomeLessQualDivAndCapGains = taxableIncome - capitalGainsPlusQualifiedDividends;
    schDLineSeven = enterSmallerNumberFunction(schDLineSixThreshold, taxableIncome);
    schDLineEight = enterSmallerNumberFunction(taxableIncomeLessQualDivAndCapGains, schDLineSeven);
    schDLineNine = schDLineSeven - schDLineEight;
    schDLineTen = enterSmallerNumberFunction(taxableIncome, capitalGainsPlusQualifiedDividends);
    schDLineTwelve = schDLineTen - schDLineEleven;
    schDLineFourteen = enterSmallerNumberFunction(taxableIncome, schDLineThirteenThreshold);
    schDLineFifteen += taxableIncomeLessQualDivAndCapGains + schDLineNine;
    schDLineSixteen = schDLineFourteen - schDLineFifteen;

    if (schDLineSixteen < 1) {
      schDLineSixteen = 0;
    }

    schDLineSeventeen = enterSmallerNumberFunction(schDLineTwelve, schDLineSixteen);
    schDLineEighteen = Math.round(schDLineSeventeen * .15);
    schDLineNineteen += schDLineNine + schDLineSeventeen;
    schDLineTwenty = schDLineTen - schDLineNineteen;
    schDLineTwentyOne = Math.round(schDLineTwenty * .20);
    totalCapitalGainsTax += schDLineEighteen + schDLineTwentyOne;
  }

  switch (expr) {
    case 'Single & Federal':
      taxBracket = taxBrackets[taxYear]['federalSingleTaxBrackets'];
      break;
    case 'MFJ & Federal':
      taxBracket = taxBrackets[taxYear]['federalMFJTaxBrackets'];
      break;
    case 'MFS & Federal':
      taxBracket = taxBrackets[taxYear]['federalMFSTaxBrackets'];
      break;
    case 'HOH & Federal':
      taxBracket = taxBrackets[taxYear]['federalHOHTaxBrackets'];
      break;
    case 'Single & New York':
      taxBracket = taxBrackets[taxYear]['newYorkStateSingleTaxBrackets'];
      break;
    case 'MFJ & New York':
      taxBracket = taxBrackets[taxYear]['newYorkStateMFJTaxBrackets'];
      break;
    case 'MFS & New York':
      taxBracket = taxBrackets[taxYear]['newYorkStateMFSTaxBrackets'];
      break;
    case 'HOH & New York':
      taxBracket = taxBrackets[taxYear]['newYorkStateHOHTaxBrackets'];
      break;
    case 'Single & New York City':
      taxBracket = taxBrackets[taxYear]['newYorkStateSingleTaxBrackets'];
      taxBracket2 = taxBrackets[taxYear]['newYorkCitySingleTaxBrackets'];
      break;
    case 'MFJ & New York City':
      taxBracket = taxBrackets[taxYear]['newYorkStateMFJTaxBrackets'];
      taxBracket2 = taxBrackets[taxYear]['newYorkCityMFJTaxBrackets'];
      break;
    case 'MFS & New York City':
      taxBracket = taxBrackets[taxYear]['newYorkStateMFSTaxBrackets'];
      taxBracket2 = taxBrackets[taxYear]['newYorkCityMFSTaxBrackets'];
      break;
    case 'HOH & New York City':
      taxBracket = taxBrackets[taxYear]['newYorkStateHOHTaxBrackets'];
      taxBracket2 = taxBrackets[taxYear]['newYorkCityHOHTaxBrackets'];
      break;
    default:
      console.log('no state selected, there is an erorr in the taxCalcfunction');
      return 0;
  }

  calculatedTax += taxBracketTaxFunction(taxBracket, taxableIncome);

  if (residency === 'Federal') {
    calculatedTaxOfTaxableIncomeLessQualDivAndCapGains += taxBracketTaxFunction(taxBracket, taxableIncomeLessQualDivAndCapGains);
  }

  totalCalculatedTaxBenefittedFromCapGainsRate += totalCapitalGainsTax + calculatedTaxOfTaxableIncomeLessQualDivAndCapGains;

  if (residency === 'Federal') {
    calculatedTax = enterSmallerNumberFunction(calculatedTax, totalCalculatedTaxBenefittedFromCapGainsRate);
  }

  if (taxBracket2 === undefined) {
    return calculatedTax;
  }
  else if (taxBracket2 !== undefined) {
    calculatedTax += taxBracketTaxFunction(taxBracket2, taxableIncome);
    return calculatedTax;
  }
  console.log('No tax calculated, there is an error in the the taxCalcFunction');
  return 0;
};

export default taxCalcFunction;
