import {
  ISchDModel,
  ITaxBracketModel,
  IStandardDeductionModel,
} from '../types';


// 2025
const maxSETaxThreshold2025 = 176100;

// 2023 values most up to date
const schDLineSix2025: ISchDModel[] = [
  { id: 0, filingStatus: 'Single', value: 47025 },
  { id: 1, filingStatus: 'MFJ', value: 94050 },
  { id: 2, filingStatus: 'MFS', value: 47025 },
  { id: 3, filingStatus: 'HOH', value: 63000 },
];

// 2023 values most up to date
const schDLineThirteen2025: ISchDModel[] = [
  { id: 0, filingStatus: 'Single', value: 518900 },
  { id: 1, filingStatus: 'MFJ', value: 583750 },
  { id: 2, filingStatus: 'MFS', value: 291850 },
  { id: 3, filingStatus: 'HOH', value: 551350 },
];

// 2025
const federalStandardDeduction2025: IStandardDeductionModel[] = [
  { id: 0, standardDeduction: -15000, filingStatus: 'Single' },
  { id: 1, standardDeduction: -15000, filingStatus: 'MFS' },
  { id: 2, standardDeduction: -30000, filingStatus: 'MFJ' },
  { id: 3, standardDeduction: -22500, filingStatus: 'HOH' },
];

// 2024
const newYorkStandardDeduction2025: IStandardDeductionModel[] = [
  { id: 0, standardDeduction: -8000, filingStatus: 'Single' },
  { id: 1, standardDeduction: -8000, filingStatus: 'MFS' },
  { id: 2, standardDeduction: -16050, filingStatus: 'MFJ' },
  { id: 3, standardDeduction: -11200, filingStatus: 'HOH' },
];


// ALL 2025 BELOW

const federalSingleTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 11925 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1193, taxBracketFloor: 11926, taxBracketCeiling: 48475 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 5579, taxBracketFloor: 48476, taxBracketCeiling: 103350 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 17651, taxBracketFloor: 103351, taxBracketCeiling: 197300 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 40199, taxBracketFloor: 197301, taxBracketCeiling: 250525 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 57231, taxBracketFloor: 250526, taxBracketCeiling: 626350 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 188770, taxBracketFloor: 626351, taxBracketCeiling: undefined },
];

const federalMFJTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 23850 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 2385, taxBracketFloor: 23851, taxBracketCeiling: 96950 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 11157, taxBracketFloor: 96951, taxBracketCeiling: 206700 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 35302, taxBracketFloor: 206701, taxBracketCeiling: 394600 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 80398, taxBracketFloor: 394601, taxBracketCeiling: 501050 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 114462, taxBracketFloor: 501051, taxBracketCeiling: 751600 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 202155, taxBracketFloor: 751601, taxBracketCeiling: undefined },
];

const federalMFSTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 11925 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1193, taxBracketFloor: 11926, taxBracketCeiling: 48475 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 5579, taxBracketFloor: 48476, taxBracketCeiling: 103350 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 17651, taxBracketFloor: 103351, taxBracketCeiling: 197300 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 40199, taxBracketFloor: 197301, taxBracketCeiling: 250525 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 57231, taxBracketFloor: 250526, taxBracketCeiling: 375800 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 101077, taxBracketFloor: 375801, taxBracketCeiling: undefined },
];

const federalHOHTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 17000 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1700, taxBracketFloor: 17001, taxBracketCeiling: 64850 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 7442, taxBracketFloor: 64851, taxBracketCeiling: 103350 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 15912, taxBracketFloor: 103351, taxBracketCeiling: 197300 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 38460, taxBracketFloor: 197301, taxBracketCeiling: 250500 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 55484, taxBracketFloor: 250501, taxBracketCeiling: 626350 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 187032, taxBracketFloor: 626351, taxBracketCeiling: undefined },
];

const newYorkStateSingleTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 8500 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 340, taxBracketFloor: 8501, taxBracketCeiling: 11700 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 484, taxBracketFloor: 11701, taxBracketCeiling: 13900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 600, taxBracketFloor: 13901, taxBracketCeiling: 80650 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 4271, taxBracketFloor: 80651, taxBracketCeiling: 215400 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 12356, taxBracketFloor: 215401, taxBracketCeiling: 1077550 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 71413, taxBracketFloor: 1077551, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 449929, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2509929, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkStateMFJTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 17150 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 686, taxBracketFloor: 17151, taxBracketCeiling: 23600 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 976, taxBracketFloor: 23601, taxBracketCeiling: 27900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 1202, taxBracketFloor: 27901, taxBracketCeiling: 161550 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 8553, taxBracketFloor: 161551, taxBracketCeiling: 323200 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 18252, taxBracketFloor: 323201, taxBracketCeiling: 2155350 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 143754, taxBracketFloor: 2155351, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 418263, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2478263, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkStateMFSTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 8500 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 340, taxBracketFloor: 8501, taxBracketCeiling: 11700 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 484, taxBracketFloor: 11701, taxBracketCeiling: 13900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 600, taxBracketFloor: 13901, taxBracketCeiling: 80650 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 4271, taxBracketFloor: 80651, taxBracketCeiling: 215400 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 12356, taxBracketFloor: 215401, taxBracketCeiling: 1077550 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 71413, taxBracketFloor: 1077551, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 449929, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2509929, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkStateHOHTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12800 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 512, taxBracketFloor: 12801, taxBracketCeiling: 17650 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 730, taxBracketFloor: 17651, taxBracketCeiling: 20900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 901, taxBracketFloor: 20901, taxBracketCeiling: 107650 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 5672, taxBracketFloor: 107651, taxBracketCeiling: 269300 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 15371, taxBracketFloor: 269301, taxBracketCeiling: 1616450 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 107651, taxBracketFloor: 1616451, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 434163, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2494163, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkCitySingleTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12000 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 369, taxBracketFloor: 12001, taxBracketCeiling: 25000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 858, taxBracketFloor: 25001, taxBracketCeiling: 50000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 1813, taxBracketFloor: 50001, taxBracketCeiling: undefined },
];

const newYorkCityMFJTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 21600 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 665, taxBracketFloor: 21601, taxBracketCeiling: 45000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 1545, taxBracketFloor: 45001, taxBracketCeiling: 90000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 3264, taxBracketFloor: 90001, taxBracketCeiling: undefined },
];

const newYorkCityMFSTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12000 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 369, taxBracketFloor: 12001, taxBracketCeiling: 25000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 858, taxBracketFloor: 25001, taxBracketCeiling: 50000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 1813, taxBracketFloor: 50001, taxBracketCeiling: undefined },
];

const newYorkCityHOHTaxBrackets2025: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 14400 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 443, taxBracketFloor: 14401, taxBracketCeiling: 30000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 1030, taxBracketFloor: 30001, taxBracketCeiling: 60000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 2176, taxBracketFloor: 60001, taxBracketCeiling: undefined },
];

export {
  maxSETaxThreshold2025,
  schDLineSix2025,
  schDLineThirteen2025,
  federalSingleTaxBrackets2025,
  federalMFJTaxBrackets2025,
  federalMFSTaxBrackets2025,
  federalHOHTaxBrackets2025,
  newYorkStateSingleTaxBrackets2025,
  newYorkStateMFJTaxBrackets2025,
  newYorkStateMFSTaxBrackets2025,
  newYorkStateHOHTaxBrackets2025,
  newYorkCitySingleTaxBrackets2025,
  newYorkCityMFJTaxBrackets2025,
  newYorkCityMFSTaxBrackets2025,
  newYorkCityHOHTaxBrackets2025,
  federalStandardDeduction2025,
  newYorkStandardDeduction2025,
};

// will need to make a note about what the tax assumptions button does, updates the following values year by year, se threshold, sch d worksheet, IRS, NY, NYC rates but
// does not include itemized deductions limits such as agi limitations, agi percentages, can I address that too
// make note, values are to date as of this date and are as most up to date as possible, sometimes there will be differences as laws and rates change
