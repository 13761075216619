import {
  ITaxAssumptions,
  INumbersInput,
  INumbersAnnualization,
  ITaxIncomeDeductionsPaymentsOutput,
  IClientInfo,
  ITaxAssumptionsReduxModel,
  IUserDataClientInfos,
  ISafeHarborInput,
  ISafeHarborPercentages,
  ISafeHarborElements,
} from '../types';

const taxAssumptionsElements: ITaxAssumptions[] = [
  {
    id: 0,
    element: 'Quarter',
    stateName: 'quarter',
    array: [
      { id: 0, arrayValue: '1' },
      { id: 1, arrayValue: '2' },
      { id: 2, arrayValue: '3' },
      { id: 3, arrayValue: '4' },
    ],
  },
  {
    id: 1,
    element: 'Filing Status',
    stateName: 'filingStatus',
    array: [
      { id: 0, arrayValue: 'Single' },
      { id: 1, arrayValue: 'MFJ' },
      { id: 2, arrayValue: 'MFS' },
      { id: 3, arrayValue: 'HOH' },
    ],
  },
  {
    id: 2,
    element: 'Standard/Itemized',
    stateName: 'standard',
    array: [
      { id: 0, arrayValue: 'Optimize' },
      { id: 1, arrayValue: 'Standard' },
      { id: 2, arrayValue: 'Itemized' },
    ],
  },
  {
    id: 3,
    element: 'Dependents',
    stateName: 'dependents',
    array: [
      { id: 0, arrayValue: '0' },
      { id: 1, arrayValue: '1' },
      { id: 2, arrayValue: '2' },
      { id: 3, arrayValue: '3' },
      { id: 4, arrayValue: '4' },
      { id: 5, arrayValue: '5' },
      { id: 6, arrayValue: '6' },
      { id: 7, arrayValue: '7' },
      { id: 8, arrayValue: '8' },
      { id: 9, arrayValue: '9' },
    ],
  },
  {
    id: 4,
    element: 'State Residency',
    stateName: 'residency',
    array: [
      { id: 0, arrayValue: 'None' },
      { id: 1, arrayValue: 'New York' },
      { id: 2, arrayValue: 'New York City' },
    ],
  },
  {
    id: 5,
    element: 'Safe Harbor Comparison',
    stateName: 'safeHarborComparison',
    array: [
      { id: 0, arrayValue: 'No' },
      { id: 1, arrayValue: 'Yes' },
    ],
  },
  {
    id: 6,
    element: 'Tax Year',
    stateName: 'taxYear',
    array: [
      { id: 0, arrayValue: '2024' },
      { id: 1, arrayValue: '2025' },
    ],
  }
];

const taxAssumptionsDefault: ITaxAssumptionsReduxModel = {
  quarter: '1',
  filingStatus: 'Single',
  standard: 'Optimize',
  dependents: '0',
  residency: 'None',
  safeHarborComparison: 'No',
  taxYear: '2024',
};

const clientInfoDefault: IClientInfo = {
  clientName: '',
  clientId: '',
};

const safeHarborInputDefault: ISafeHarborInput = {
  federalSafeHarborPercentage: '100%',
  stateSafeHarborPercentage: '100%',
  federalPriorYearTax: '0',
  statePriorYearTax: '0',
};

const safeHarborPercentages: ISafeHarborPercentages[] = [
  { key: 0, arrayValue: '90%' },
  { key: 1, arrayValue: '100%' },
  { key: 2, arrayValue: '110%' },
  { key: 3, arrayValue: '120%' },
];

const safeHarborSelectElements: ISafeHarborElements[] = [
  { key: 0, element: 'Federal Safe Harbor Percentage', stateName: 'federalSafeHarborPercentage', array: safeHarborPercentages },
  { key: 1, element: 'State Safe Harbor Percentage', stateName: 'stateSafeHarborPercentage', array: safeHarborPercentages },
];

const safeHarborInputElements: ISafeHarborElements[] = [
  { key: 0, element: 'Federal Prior Year Tax', stateName: 'federalPriorYearTax' },
  { key: 1, element: 'State Prior Tear Tax', stateName: 'statePriorYearTax' },
];

// numbersInputDefault for each description key must match respective constants.js hardValue
const numbersInputDefault: INumbersInput = {
  wages: '0',
  interest: '0',
  ordinaryDividends: '0',
  qualifiedDividends: '0',
  retirementIncome: '0',
  shortCapitalIncome: '0',
  longCapitalIncome: '0',
  businessIncome: '0',
  otherIncome: '0',
  hsa: '0',
  seHealthInsurance: '0',
  retirementDeduction: '0',
  studentLoan: '0',
  otherAdjustments: '0',
  medical: '0',
  taxesPaidItemized: '0',
  interestPaidItemized: '0',
  charitySixty: '0',
  charityFifty: '0',
  charityThirty: '0',
  charityOther: '0',
  otherFederalItemized: '0',
  statePortfolioItemized: '0',
  stateSpecificItemized: '0',
  qbi: '0',
  otherFedDeductions: '0',
  otherFedTaxes: '0',
  otherStateIncomeDeductions: '0',
  stateAdditions: '0',
  stateSubtractions: '0',
  otherStateTaxes: '0',
  fedWagesWithholding: '0',
  fedPYOP: '0',
  fedQ1: '0',
  fedQ2: '0',
  fedQ3: '0',
  fedQ4: '0',
  fedCredits: '0',
  stateWagesWithholding: '0',
  statePYOP: '0',
  stateQ1: '0',
  stateQ2: '0',
  stateQ3: '0',
  stateQ4: '0',
  stateCredits: '0',
};

const numbersAnnualizationDefault: INumbersAnnualization = {
  wages: 'on',
  interest: 'on',
  ordinaryDividends: 'on',
  qualifiedDividends: 'on',
  retirementIncome: 'on',
  shortCapitalIncome: 'off',
  longCapitalIncome: 'off',
  businessIncome: 'on',
  otherIncome: 'on',
  hsa: 'on',
  seHealthInsurance: 'on',
  retirementDeduction: 'on',
  studentLoan: 'on',
  otherAdjustments: 'on',
  medical: 'on',
  taxesPaidItemized: 'on',
  interestPaidItemized: 'on',
  charitySixty: 'on',
  charityFifty: 'on',
  charityThirty: 'on',
  charityOther: 'on',
  otherFederalItemized: 'on',
  stateSpecificItemized: 'on',
  qbi: 'on',
  otherFedDeductions: 'on',
  otherFedTaxes: 'on',
  statePortfolioItemized: 'on',
  otherStateIncomeDeductions: 'on',
  stateAdditions: 'on',
  stateSubtractions: 'on',
  otherStateTaxes: 'on',
  fedWagesWithholding: 'on',
  fedPYOP: 'na',
  fedQ1: 'na',
  fedQ2: 'na',
  fedQ3: 'na',
  fedQ4: 'na',
  fedCredits: 'on',
  stateWagesWithholding: 'on',
  statePYOP: 'na',
  stateQ1: 'na',
  stateQ2: 'na',
  stateQ3: 'na',
  stateQ4: 'na',
  stateCredits: 'on',
};

const taxIncomeElements: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Wages (W-2)', hardValue: 'wages' },
  { id: 1, element: 'Interest', hardValue: 'interest' },
  { id: 2, element: 'Ordinary Dividends', hardValue: 'ordinaryDividends' },
  { id: 3, element: 'Qualified Dividends', hardValue: 'qualifiedDividends' },
  { id: 4, element: 'Retirement Income', hardValue: 'retirementIncome' },
  { id: 5, element: 'Short-Term Capital Gains/(Losses)', hardValue: 'shortCapitalIncome' },
  { id: 6, element: 'Long-Term Capital Gains/(Losses)', hardValue: 'longCapitalIncome' },
  { id: 7, element: 'Business Income (Schedule C)', hardValue: 'businessIncome' },
  { id: 8, element: 'Other Income', hardValue: 'otherIncome' },
];

const taxAdjustmentElements: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'HSA Contributions', hardValue: 'hsa' },
  { id: 1, element: 'S/E Health Insurance', hardValue: 'seHealthInsurance' },
  { id: 2, element: 'Retirement Deduction', hardValue: 'retirementDeduction' },
  { id: 3, element: 'Student Loan Interest', hardValue: 'studentLoan' },
  { id: 4, element: 'Other Adjustments', hardValue: 'otherAdjustments' },
];

const taxItemizedDeductionElements: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Medical *(SL)', hardValue: 'medical' },
  { id: 1, element: 'Taxes Paid *(SL)', hardValue: 'taxesPaidItemized' },
  { id: 2, element: 'Interest Paid', hardValue: 'interestPaidItemized' },
  { id: 3, element: 'Charity (60%) *(SL)', hardValue: 'charitySixty' },
  { id: 4, element: 'Charity (50%) *(SL)', hardValue: 'charityFifty' },
  { id: 5, element: 'Charity (30%) *(SL)', hardValue: 'charityThirty' },
  { id: 6, element: 'Charity Other', hardValue: 'charityOther' },
  { id: 7, element: 'Other Federal Itemized Deductions', hardValue: 'otherFederalItemized' },
];

const taxStateItemizedDeductionElements: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'State Portfolio Deductions *(SL)', hardValue: 'statePortfolioItemized' },
  { id: 1, element: 'State Specific Itemized Deductions', hardValue: 'stateSpecificItemized' },
];

const taxOtherFederal: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Qualified Business Income Deduction (S/B Negative)', hardValue: 'qbi' },
  { id: 1, element: 'Other Federal Income /(Deductions)', hardValue: 'otherFedDeductions' },
];

const taxOtherState: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Other State Income /(Deductions)', hardValue: 'otherStateIncomeDeductions' },
  { id: 1, element: 'State Additions (S/B Positive)', hardValue: 'stateAdditions' },
  { id: 2, element: 'State Subtractions (S/B Negative)', hardValue: 'stateSubtractions' },
];

const paymentsFederal: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Wages Withholding', hardValue: 'fedWagesWithholding' },
  { id: 1, element: 'Prior Year Overpayment', hardValue: 'fedPYOP' },
  { id: 2, element: 'First Quarter Estimate', hardValue: 'fedQ1' },
  { id: 3, element: 'Second Quarter Estimate', hardValue: 'fedQ2' },
  { id: 4, element: 'Third Quarter Estimate', hardValue: 'fedQ3' },
  { id: 5, element: 'Fourth Quarter Estimate', hardValue: 'fedQ4' },
  { id: 6, element: 'Credits', hardValue: 'fedCredits' },
];

const paymentsState: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Wages Withholding', hardValue: 'stateWagesWithholding' },
  { id: 1, element: 'Prior Year Overpayment', hardValue: 'statePYOP' },
  { id: 2, element: 'First Quarter Estimate', hardValue: 'stateQ1' },
  { id: 3, element: 'Second Quarter Estimate', hardValue: 'stateQ2' },
  { id: 4, element: 'Third Quarter Estimate', hardValue: 'stateQ3' },
  { id: 5, element: 'Fourth Quarter Estimate', hardValue: 'stateQ4' },
  { id: 6, element: 'Credits', hardValue: 'stateCredits' },
];

const otherTaxes: ITaxIncomeDeductionsPaymentsOutput[] = [
  { id: 0, element: 'Other Federal Taxes', hardValue: 'otherFedTaxes' },
  { id: 1, element: 'Other State Taxes', hardValue: 'otherStateTaxes' },
];

const initialUserDataClientInfos: IUserDataClientInfos[] = [
  {
    clientInfo: {
      clientName: '',
      clientId: '',
    },
  },
];

export {
  numbersInputDefault,
  numbersAnnualizationDefault,
  taxAssumptionsElements,
  taxAssumptionsDefault,
  clientInfoDefault,
  safeHarborInputDefault,
  safeHarborSelectElements,
  safeHarborInputElements,
  taxIncomeElements,
  taxAdjustmentElements,
  taxItemizedDeductionElements,
  taxStateItemizedDeductionElements,
  taxOtherFederal,
  taxOtherState,
  paymentsFederal,
  paymentsState,
  otherTaxes,
  initialUserDataClientInfos,
};
