import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import CopyrightIcon from '@mui/icons-material/Copyright';
import './footer.scss';

interface Props {
  sideBarNavSummaryShow: boolean;
}

const Footer = (props: Props) => {

  const year = new Date().getFullYear();

  return (
    <div id="footerContact" className={props.sideBarNavSummaryShow ? 'footer-container' : 'footer-container-full-width'}>
      <div className="footer-contact-container">
        <div className="footer-column">
          <h1>ABOUT</h1>
          Developed and built by <b>Russell Monteith</b> who is a Certified Public Accountant and Professional Software Engineer based out of New York City.
        </div>
        <div className="footer-column">
          <h1>CONTACT</h1>
          For bugs, feedback, questions, tax/tech consultations, or partnerships, send <b>Russell</b> an e-mail at <a href="mailto: russell@monteithtaxcpa.com"><b>russell@monteithtaxcpa.com</b></a>
        </div>
        <div className="footer-column">
          <h1>CONNECT</h1>
          <a href="https://www.linkedin.com/in/russell-monteith-cpa-0a43975a/" target="_blank" rel="noreferrer" className='footer-social-icons'>
            <LinkedInIcon />
          </a>
          <a href="https://github.com/russelltheprogrammer" target="_blank" rel="noreferrer" className='footer-social-icons'>
            <GitHubIcon />
          </a>
          <a href="mailto:russell@monteithtaxcpa.com" className='footer-social-icons'>
            <EmailIcon />
          </a>
        </div>
      </div>
      <div className="footer-info-container">
        <div className="footer-info">
          <p>
            <span style={{'verticalAlign': 'middle'}}><CopyrightIcon /></span>&nbsp;&nbsp;Copyright {year} - All Rights Reserved - RUSSELL MONTEITH CPA PLLC
          </p>
        </div>
        <div className="footer-disclaimer">
          <h3>INFORMATION &amp; DISCLAIMER</h3>
          <p>This tax projection application is currently set up for 2024 & 2025 tax years as of 01/09/2025. This is for Individual tax return types only, other tax return types will not work.
            At this time, this projection application can only handle New York State and New York City tax jurisdictions and states with no income tax.
            Please see contact information if you have any questions, feature requests, and/or to report any bugs. Please check out the&ensp;
          <a
            href="https://github.com/russelltheprogrammer/tax-projection-individual-docs#readme"
            target="_blank"
            rel="noreferrer"
            className='footer-disclaimer-link'>
              DOCUMENTATION
          </a>
            &ensp;for instructions on how to use this application and for additional information. This application should not be relied upon to estimate your quarterly
            income taxes or project other taxes. Please consult a tax professional while using this application. Although mobile responsive, it is recommended to
            use this application on desktop because of the large amount of data input required.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
