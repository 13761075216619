import { schDLineSix2024, schDLineThirteen2024 } from '../constants/values2024';
import { schDLineSix2025, schDLineThirteen2025 } from '../constants/values2025';
import { ISchDModel } from '../types';

interface ISchDLines  {
  [key: string]: ISchDModel[][];
}

const schDLines: ISchDLines = {
  '2024': [schDLineSix2024, schDLineThirteen2024],
  '2025': [ schDLineSix2025, schDLineThirteen2025],
}

const schDTaxWorksheetLineThresholdCalculation = (filingStatus: string, schDLineNumber: string, taxYear: string): number => {
  let array: ISchDModel[];
  const schDLineSix = schDLines[taxYear][0];
  const schDLineThirteen = schDLines[taxYear][1];

  if (schDLineNumber === 'SchDLineSix') {
    array = schDLineSix;
  }
  else {
    array = schDLineThirteen;
  }

  for (let i = 0; i < array.length; i++) {
    if (filingStatus === array[i]['filingStatus']) {
      return array[i]['value'];
    }
  }
  console.log('error in schDTaxWorksheetLineThresholdCalculation');
  return 0;
};

export default schDTaxWorksheetLineThresholdCalculation;
