import { federalStandardDeduction2024, newYorkStandardDeduction2024 } from '../constants/values2024';
import { federalStandardDeduction2025, newYorkStandardDeduction2025 } from '../constants/values2025';
import { IStandardDeductionModel } from '../types';

interface IStandardDeduction {
  [key: string]: IStandardDeductionModel[];
}

const federalStandardDeductions: IStandardDeduction = {
  '2024': federalStandardDeduction2024,
  '2025': federalStandardDeduction2025,
}

const newYorkStandardDeductions: IStandardDeduction = {
  '2024': newYorkStandardDeduction2024,
  '2025': newYorkStandardDeduction2025,
}

const standardFunction = (jurisdiction: string, filingStatus: string, taxYear: string): number => {
  let dependentData: IStandardDeductionModel[];

  if (jurisdiction === 'federal') {
    dependentData = federalStandardDeductions[taxYear];
  }
  else if (jurisdiction === 'newYork') {
    dependentData = newYorkStandardDeductions[taxYear];
  }
  else {
    // "no jurisdiction selected in standardFunction"
    console.log('no jurisdiction selected in standardFunction');
    return 0;
  }

  for (let i = 0; i < dependentData.length; i++) {
    if (dependentData[i]['filingStatus'] === filingStatus) {
      return dependentData[i].standardDeduction;
    }
  }
  // "error in standardFunction"
  console.log('error in standardFunction');
  return 0;
};

export default standardFunction;
