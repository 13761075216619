import React, { useState, FormEvent, MouseEvent, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { buttonThemeLoggedIn } from './themesLoginDropDown';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  taxProjectionDataSelector,
  clientInfoAction,
  taxAssumptionsAction,
  numbersAnnualizationAction,
  numbersInputAction,
  safeHarborInputAction,
  taxNotesAction,
} from '../../../redux/reducers/taxProjectionDataSlice';
import ConfirmationBox from './ConfirmationBox';
import DataExchangeBox from './DataExchangeBox';
import UserProfileBox from './UserProfileBox';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import {
  clientInfoDefault,
  taxAssumptionsDefault,
  numbersAnnualizationDefault,
  numbersInputDefault,
  initialUserDataClientInfos,
  safeHarborInputDefault
} from '../../constants/generalConstants';
import formatTaxProjectionData from '../../functions/formatTaxProjectionData';
import { IUserDataClientInfos, IUserInfoToSave, IDataToSave, ITaxProjectionDataToSave } from '../../types';
import { useAuth0 } from '@auth0/auth0-react';
import './loginDropDown.scss';

const LoginDropDown = () => {

  const taxProjectionDataFromStore: ITaxProjectionDataToSave = useAppSelector(taxProjectionDataSelector);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showUserBox, setShowUserBox] = useState(false);
  const [userDataClients, setUserDataClients] = useState<IUserDataClientInfos[]>(initialUserDataClientInfos);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [dataExchangeBox, setDataExchangeBox] = useState(false);
  const [loginDropDownMethodType, setLoginDropDownMethodType] = useState('');
  const [message, setMessage] = useState('');
  const [dataSaved, setDataSaved] = useState(false);
  const [sendToApi, setSendToApi] = useState(false);
  const [userInitials, setUserInitials] = useState<undefined | string>(undefined);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const open = Boolean(anchorEl);
  const audience = process.env.REACT_APP_AUDIENCE;
  const productionApiBaseUrl = process.env.REACT_APP_PRODUCTIONAPIBASEURL;
  const apiBaseUrl = process.env.NODE_ENV === 'production' ? productionApiBaseUrl : 'http://localhost:3001';
  const dispatch = useAppDispatch();

  useEffect(() => {
    handleUserInitials();
  }, [user]);


  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAccessToken = async (audience: string | undefined) => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: audience
        },
      });
      return accessToken;
    } catch (error) {
      console.error(error);
    }
  };

  const handleUserInitials = () => {
    if (user !== undefined) {
      const userInitials = user.nickname?.substring(0, 2);
      setUserInitials(userInitials);
    }
    else {
      return null;
    }
  };

  const handleUserProfileBox = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleClose();
    await handleProfileData();
    setShowUserBox(true);
  };

  const handleProfileData = async () => {
    try {
      const accessToken = await getAccessToken(audience);

      const response = await fetch(`${apiBaseUrl}/api/taxprojectiondata/userinfo/${user?.sub}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response error');
      }

      const userDataClientInfosFromDatabase: IUserDataClientInfos[] = await response.json();
      setUserDataClients(userDataClientInfosFromDatabase);

    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmationBoxConfirmation = async () => {
    if (!sendToApi) {
      setShowConfirmationBox(false);
      setDataSaved(false);
    }
    else if (sendToApi && !dataSaved) {
      await saveData();
    }
    else if (sendToApi && dataSaved) {
      setSendToApi(false);
      setShowConfirmationBox(false);
      setDataSaved(false);
    }
    else {
      alert('Error in confirmation box. Please contact system administrator.');
    }
  };

  const handleConfirmationBoxCancel = () => {
    setSendToApi(false);
    setDataSaved(false);
    setShowUserBox(false);
    setShowConfirmationBox(false);
    setDataExchangeBox(false);
  };

  const handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleClose();

    if (!taxProjectionDataFromStore.clientInfo.clientName) {
      setMessage('Client Name is missing. Please add and try to save again.');
      setShowConfirmationBox(true);
    }
    else if (!taxProjectionDataFromStore.clientInfo.clientId) {
      setMessage('Client ID is missing. Please add and try to save again.');
      setShowConfirmationBox(true);
    }
    else if (taxProjectionDataFromStore.clientInfo.clientName && taxProjectionDataFromStore.clientInfo.clientId) {
      setSendToApi(true);
      setMessage('Are you sure you want to save? This will overwrite any data currently saved in the database for this client.');
      setShowConfirmationBox(true);
    }
    else {
      alert('Error has occurred. Please contact system administrator.');
    }
  };

  // potentially save userEmail in the future but currently I do not see a need to save it so I am only saving an empty string
  const saveData = async () => {
    try {
      const accessToken = await getAccessToken(audience);
      const userInfoToSave: IUserInfoToSave = {
        username: user?.nickname,
        userId: user?.sub,
        userEmail: '',
        userEmailVerified: user?.email_verified
      };

      const taxProjectionDataFormattedToSave: ITaxProjectionDataToSave = formatTaxProjectionData(taxProjectionDataFromStore);
      const dataToSave: IDataToSave = {
        taxProjectionData: taxProjectionDataFormattedToSave,
        userInfo: userInfoToSave
      };
      const response = await fetch(`${apiBaseUrl}/api/taxprojectiondata`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(dataToSave),
      });
      const data = await response.json();
      if (data && data.status) {
        setMessage(data.status);
        setDataSaved(true);
      } else {
        setMessage(data.message);
        if (!data.message) {
          alert('No status found in the response.');
        }
        setSendToApi(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>, method: string) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);
    const clientName = JSON.stringify(formData.get('clientName'));
    const clientId = JSON.stringify(formData.get('clientId'));

    if (method === 'LOAD') {
      loadData(clientName, clientId);
    }
    if (method === 'DELETE') {
      deleteData(clientName, clientId);
    }
  };

  const loadData = async (clientName: string, clientId: string) => {
    try {
      const accessToken = await getAccessToken(audience);

      const response = await fetch(`${apiBaseUrl}/api/taxprojectiondata/clientname/${clientName}/clientid/${clientId}/userId/${user?.sub}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response error');
      }

      const dataFromApi = await response.json();
      const dataTaxProjectionFormat = dataFromApi.taxProjectionData;

      // refactor this function, it is the similar in the load and delete function
      if (!dataTaxProjectionFormat) {
        setDataExchangeBox(false);
        setMessage('This client was not found in the database.');
        setShowConfirmationBox(true);
        return;
      }
      // Temporary code to handle the missing taxYear variable in the database, should be improved in the future, currently the database has saved clients data that do not have the taxYear variable
      // because it was added later. This code will add the taxYear variable to the client data if it is missing. We should make this variable required in the future, might need to create a migration
      // function to add this variable to all clients that do not have it that are currently in the database.
      if (!dataTaxProjectionFormat.taxAssumptions.taxYear) {
        dataTaxProjectionFormat.taxAssumptions.taxYear = '2025';
      }
      dispatch(clientInfoAction(dataTaxProjectionFormat.clientInfo));
      dispatch(taxAssumptionsAction(dataTaxProjectionFormat.taxAssumptions));
      dispatch(numbersAnnualizationAction(dataTaxProjectionFormat.numbersAnnualization));
      dispatch(numbersInputAction(dataTaxProjectionFormat.numbersInput));
      dispatch(safeHarborInputAction(dataTaxProjectionFormat.safeHarborInput));
      dispatch(taxNotesAction(dataTaxProjectionFormat.taxNotes));
      setDataExchangeBox(false);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteData = async (clientName: string, clientId: string) => {
    try {
      const accessToken = await getAccessToken(audience);

      const response = await fetch(`${apiBaseUrl}/api/taxprojectiondata/clientname/${clientName}/clientid/${clientId}/userId/${user?.sub}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response error');
      }

      const dataFromApi = await response.json();

      // refactor this function, it is the similar in the load and delete function
      if (!dataFromApi.deleted) {
        setDataExchangeBox(false);
        setMessage('This client was not found in the database.');
        setShowConfirmationBox(true);
        return;
      }
      else if (dataFromApi.deleted) {
        dispatch(clientInfoAction(clientInfoDefault));
        dispatch(taxAssumptionsAction(taxAssumptionsDefault));
        dispatch(numbersAnnualizationAction(numbersAnnualizationDefault));
        dispatch(numbersInputAction(numbersInputDefault));
        dispatch(safeHarborInputAction(safeHarborInputDefault));
        dispatch(taxNotesAction(''));
        setDataExchangeBox(false);
        setMessage('This client was deleted from the database.');
        setShowConfirmationBox(true);
      }
      else {
        alert('Error in delete function. Please contact system administrator.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDataExchangeBox = async (method: string) => {
    if (method === 'LOAD') {
      setLoginDropDownMethodType(method);
    }
    if (method === 'DELETE') {
      setLoginDropDownMethodType(method);
    }
    setDataExchangeBox(true);
    handleClose();
  };

  if (user !== undefined && isAuthenticated) {
    return (
      <div>
        <ThemeProvider theme={buttonThemeLoggedIn}>
          <Tooltip title="Login Functions">
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              {userInitials}
            </Button>
          </Tooltip>
        </ThemeProvider>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {/* Should refactor all buttons at some point */}
          <button className='login-drop-down-item' onClick={handleUserProfileBox}>PROFILE</button>
          <button className='login-drop-down-item' onClick={handleSave}>SAVE</button>
          <button className='login-drop-down-item' onClick={() => handleDataExchangeBox('LOAD')}>LOAD</button>
          <button className='login-drop-down-item' onClick={() => handleDataExchangeBox('DELETE')}>DELETE</button>
          <LogoutButton />
        </Menu>
        {/* User Profile Data */}
        <UserProfileBox
          isOpen={showUserBox}
          userData={userDataClients}
          handleCancel={handleConfirmationBoxCancel}
        />
        {/* Save Box & Other Messages */}
        <ConfirmationBox
          message={message}
          isOpen={showConfirmationBox}
          sendToApi={sendToApi}
          handleConfirmation={handleConfirmationBoxConfirmation}
          handleCancel={handleConfirmationBoxCancel}
        />
        {/* Load Box & Delete Box */}
        <DataExchangeBox
          isOpen={dataExchangeBox}
          methodType={loginDropDownMethodType}
          handleSubmit={handleSubmit}
          handleCancel={handleConfirmationBoxCancel}
        />
      </div>
    );
  }
  else {
    return (
      <div>
        <LoginButton
          open={open}
        />
      </div>
    );
  }
};

export default LoginDropDown;
