import {
  ISchDModel,
  ITaxBracketModel,
  IStandardDeductionModel,
} from '../types';


// 2024
const maxSETaxThreshold2024 = 168600;

// 2023 values most up to date
const schDLineSix2024: ISchDModel[] = [
  { id: 0, filingStatus: 'Single', value: 47025 },
  { id: 1, filingStatus: 'MFJ', value: 94050 },
  { id: 2, filingStatus: 'MFS', value: 47025 },
  { id: 3, filingStatus: 'HOH', value: 63000 },
];

// 2023 values most up to date
const schDLineThirteen2024: ISchDModel[] = [
  { id: 0, filingStatus: 'Single', value: 518900 },
  { id: 1, filingStatus: 'MFJ', value: 583750 },
  { id: 2, filingStatus: 'MFS', value: 291850 },
  { id: 3, filingStatus: 'HOH', value: 551350 },
];

// 2024
const federalStandardDeduction2024: IStandardDeductionModel[] = [
  { id: 0, standardDeduction: -14600, filingStatus: 'Single' },
  { id: 1, standardDeduction: -14600, filingStatus: 'MFS' },
  { id: 2, standardDeduction: -29200, filingStatus: 'MFJ' },
  { id: 3, standardDeduction: -21900, filingStatus: 'HOH' },
];

// 2024
const newYorkStandardDeduction2024: IStandardDeductionModel[] = [
  { id: 0, standardDeduction: -8000, filingStatus: 'Single' },
  { id: 1, standardDeduction: -8000, filingStatus: 'MFS' },
  { id: 2, standardDeduction: -16050, filingStatus: 'MFJ' },
  { id: 3, standardDeduction: -11200, filingStatus: 'HOH' },
];


// ALL 2024 BELOW

const federalSingleTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 11600 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1160, taxBracketFloor: 11601, taxBracketCeiling: 47150 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 5426, taxBracketFloor: 47151, taxBracketCeiling: 100525 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 17169, taxBracketFloor: 100526, taxBracketCeiling: 191950 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 39111, taxBracketFloor: 191951, taxBracketCeiling: 243725 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 55679, taxBracketFloor: 243726, taxBracketCeiling: 609350 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 183647, taxBracketFloor: 609351, taxBracketCeiling: undefined },
];

const federalMFJTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 23200 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 2320, taxBracketFloor: 23201, taxBracketCeiling: 94300 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 10852, taxBracketFloor: 94301, taxBracketCeiling: 201050 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 34337, taxBracketFloor: 201051, taxBracketCeiling: 383900 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 78221, taxBracketFloor: 383901, taxBracketCeiling: 487450 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 111357, taxBracketFloor: 487451, taxBracketCeiling: 731200 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 196670, taxBracketFloor: 731201, taxBracketCeiling: undefined },
];

const federalMFSTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 11600 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1160, taxBracketFloor: 11601, taxBracketCeiling: 47150 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 5426, taxBracketFloor: 47151, taxBracketCeiling: 100525 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 17169, taxBracketFloor: 100526, taxBracketCeiling: 191950 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 39111, taxBracketFloor: 191951, taxBracketCeiling: 243725 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 55679, taxBracketFloor: 243726, taxBracketCeiling: 365600 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 98335, taxBracketFloor: 365601, taxBracketCeiling: undefined },
];

const federalHOHTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '10%', rate: 0.1, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 16550 },
  { id: 1, bracket: '12%', rate: 0.12, totalTaxBelowBracket: 1655, taxBracketFloor: 16551, taxBracketCeiling: 63100 },
  { id: 2, bracket: '22%', rate: 0.22, totalTaxBelowBracket: 7241, taxBracketFloor: 63101, taxBracketCeiling: 100500 },
  { id: 3, bracket: '24%', rate: 0.24, totalTaxBelowBracket: 15469, taxBracketFloor: 100501, taxBracketCeiling: 191950 },
  { id: 4, bracket: '32%', rate: 0.32, totalTaxBelowBracket: 37417, taxBracketFloor: 191951, taxBracketCeiling: 243700 },
  { id: 5, bracket: '35%', rate: 0.35, totalTaxBelowBracket: 53977, taxBracketFloor: 243701, taxBracketCeiling: 609350 },
  { id: 6, bracket: '37%', rate: 0.37, totalTaxBelowBracket: 181955, taxBracketFloor: 609351, taxBracketCeiling: undefined },
];

const newYorkStateSingleTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 8500 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 340, taxBracketFloor: 8501, taxBracketCeiling: 11700 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 484, taxBracketFloor: 11701, taxBracketCeiling: 13900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 600, taxBracketFloor: 13901, taxBracketCeiling: 80650 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 4271, taxBracketFloor: 80651, taxBracketCeiling: 215400 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 12356, taxBracketFloor: 215401, taxBracketCeiling: 1077550 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 71413, taxBracketFloor: 1077551, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 449929, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2509929, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkStateMFJTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 17150 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 686, taxBracketFloor: 17151, taxBracketCeiling: 23600 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 976, taxBracketFloor: 23601, taxBracketCeiling: 27900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 1202, taxBracketFloor: 27901, taxBracketCeiling: 161550 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 8553, taxBracketFloor: 161551, taxBracketCeiling: 323200 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 18252, taxBracketFloor: 323201, taxBracketCeiling: 2155350 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 143754, taxBracketFloor: 2155351, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 418263, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2478263, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkStateMFSTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 8500 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 340, taxBracketFloor: 8501, taxBracketCeiling: 11700 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 484, taxBracketFloor: 11701, taxBracketCeiling: 13900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 600, taxBracketFloor: 13901, taxBracketCeiling: 80650 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 4271, taxBracketFloor: 80651, taxBracketCeiling: 215400 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 12356, taxBracketFloor: 215401, taxBracketCeiling: 1077550 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 71413, taxBracketFloor: 1077551, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 449929, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2509929, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkStateHOHTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '4%', rate: 0.04, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12800 },
  { id: 1, bracket: '4.5%', rate: 0.045, totalTaxBelowBracket: 512, taxBracketFloor: 12801, taxBracketCeiling: 17650 },
  { id: 2, bracket: '5.25%', rate: 0.0525, totalTaxBelowBracket: 730, taxBracketFloor: 17651, taxBracketCeiling: 20900 },
  { id: 3, bracket: '5.5%', rate: 0.055, totalTaxBelowBracket: 901, taxBracketFloor: 20901, taxBracketCeiling: 107650 },
  { id: 4, bracket: '6.0%', rate: 0.06, totalTaxBelowBracket: 5672, taxBracketFloor: 107651, taxBracketCeiling: 269300 },
  { id: 5, bracket: '6.85%', rate: 0.0685, totalTaxBelowBracket: 15371, taxBracketFloor: 269301, taxBracketCeiling: 1616450 },
  { id: 6, bracket: '9.65%', rate: 0.0965, totalTaxBelowBracket: 107651, taxBracketFloor: 1616451, taxBracketCeiling: 5000000 },
  { id: 7, bracket: '10.3%', rate: 0.103, totalTaxBelowBracket: 434163, taxBracketFloor: 5000001, taxBracketCeiling: 25000000 },
  { id: 8, bracket: '10.9%', rate: 0.109, totalTaxBelowBracket: 2494163, taxBracketFloor: 25000001, taxBracketCeiling: undefined },
];

const newYorkCitySingleTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12000 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 369, taxBracketFloor: 12001, taxBracketCeiling: 25000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 858, taxBracketFloor: 25001, taxBracketCeiling: 50000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 1813, taxBracketFloor: 50001, taxBracketCeiling: undefined },
];

const newYorkCityMFJTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 21600 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 665, taxBracketFloor: 21601, taxBracketCeiling: 45000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 1545, taxBracketFloor: 45001, taxBracketCeiling: 90000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 3264, taxBracketFloor: 90001, taxBracketCeiling: undefined },
];

const newYorkCityMFSTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 12000 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 369, taxBracketFloor: 12001, taxBracketCeiling: 25000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 858, taxBracketFloor: 25001, taxBracketCeiling: 50000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 1813, taxBracketFloor: 50001, taxBracketCeiling: undefined },
];

const newYorkCityHOHTaxBrackets2024: ITaxBracketModel[] = [
  { id: 0, bracket: '3.078%', rate: 0.03078, totalTaxBelowBracket: 0, taxBracketFloor: undefined, taxBracketCeiling: 14400 },
  { id: 1, bracket: '3.762%', rate: 0.03762, totalTaxBelowBracket: 443, taxBracketFloor: 14401, taxBracketCeiling: 30000 },
  { id: 2, bracket: '3.819%', rate: 0.03819, totalTaxBelowBracket: 1030, taxBracketFloor: 30001, taxBracketCeiling: 60000 },
  { id: 3, bracket: '3.876%', rate: 0.03876, totalTaxBelowBracket: 2176, taxBracketFloor: 60001, taxBracketCeiling: undefined },
];

export {
  maxSETaxThreshold2024,
  schDLineSix2024,
  schDLineThirteen2024,
  federalSingleTaxBrackets2024,
  federalMFJTaxBrackets2024,
  federalMFSTaxBrackets2024,
  federalHOHTaxBrackets2024,
  newYorkStateSingleTaxBrackets2024,
  newYorkStateMFJTaxBrackets2024,
  newYorkStateMFSTaxBrackets2024,
  newYorkStateHOHTaxBrackets2024,
  newYorkCitySingleTaxBrackets2024,
  newYorkCityMFJTaxBrackets2024,
  newYorkCityMFSTaxBrackets2024,
  newYorkCityHOHTaxBrackets2024,
  federalStandardDeduction2024,
  newYorkStandardDeduction2024,
};
